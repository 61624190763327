import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Progress
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTicketAlt,
  faCity
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import './Dashboard.scss';

const CityDashboard = () => {
  // Mock data - to be replaced with actual API call
  const [cityStats] = useState([
    {
      city: 'Mumbai',
      totalTickets: 250,
      totalAmount: 425000,
      ticketTypes: {
        regular: 150,
        vip: 50,
        student: 50
      },
      image: '/images/cities/mumbai.jpg'
    },
    {
      city: 'Delhi',
      totalTickets: 180,
      totalAmount: 320000,
      ticketTypes: {
        regular: 100,
        vip: 40,
        student: 40
      },
      image: '/images/cities/delhi.jpg'
    },
    {
      city: 'Bangalore',
      totalTickets: 220,
      totalAmount: 390000,
      ticketTypes: {
        regular: 130,
        vip: 45,
        student: 45
      },
      image: '/images/cities/bangalore.jpg'
    },
    {
      city: 'Chennai',
      totalTickets: 150,
      totalAmount: 265000,
      ticketTypes: {
        regular: 90,
        vip: 30,
        student: 30
      },
      image: '/images/cities/chennai.jpg'
    },
    {
      city: 'Hyderabad',
      totalTickets: 190,
      totalAmount: 340000,
      ticketTypes: {
        regular: 110,
        vip: 40,
        student: 40
      },
      image: '/images/cities/hyderabad.jpg'
    }
  ]);

  const getTicketTypeColor = (type) => {
    switch (type) {
      case 'regular': return 'primary';
      case 'vip': return 'success';
      case 'student': return 'warning';
      default: return 'secondary';
    }
  };

  const calculatePercentage = (value, total) => {
    return Math.round((value / total) * 100);
  };

  return (
    <Container fluid className="city-dashboard">
      <Row className="mb-4">
        <Col>
          <PageTitle
            heading="City Ticket Dashboard"
            subheading="Overview of ticket sales across different cities"
            icon={faCity}
            titleClassName="text-primary"
            subtitleClassName="text-muted"
            iconClassName="bg-transparent"
          />
        </Col>
      </Row>

      <Row>
        {cityStats.map((city, index) => (
          <Col key={index} md={6} lg={4} className="mb-4">
            <Card className="city-stats-card">
              <div 
                className="city-image-overlay" 
                style={{ backgroundImage: `url(${city.image})` }}
              />
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="mb-0">{city.city}</h4>
                  <Badge color="light" className="text-dark">
                    <FontAwesomeIcon icon={faTicketAlt} className="me-1" />
                    {city.totalTickets}
                  </Badge>
                </div>

                <div className="stats-section mt-3">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Total Revenue</span>
                    <strong>₹{city.totalAmount.toLocaleString()}</strong>
                  </div>
                  <div className="ticket-type-breakdown">
                    {Object.entries(city.ticketTypes).map(([type, count]) => (
                      <div key={type} className="mb-2">
                        <div className="d-flex justify-content-between">
                          <span className="text-capitalize">{type} Tickets</span>
                          <span>{count} ({calculatePercentage(count, city.totalTickets)}%)</span>
                        </div>
                        <Progress 
                          value={calculatePercentage(count, city.totalTickets)} 
                          color={getTicketTypeColor(type)}
                          className="mt-1"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CityDashboard;
