import "./polyfills";
import React from "react";
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";

const rootElement = document.getElementById("root");

const renderApp = (Component) => (
  <BrowserRouter>
    <Component />
  </BrowserRouter>
);

const root = createRoot(rootElement).render(renderApp(Main));

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    root.render(renderApp(NextApp));
  });
}

serviceWorker.unregister();