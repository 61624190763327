import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  Form, 
  FormGroup, 
  Label, 
  Input, 
  Button,
  Spinner,
  Alert
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { TicketService } from '../../../services/api';
import './Settings.scss';

const Settings = () => {
  const [settings, setSettings] = useState({
    eventName: 'OrindIndia Event 2025',
    maxParticipantsPerRegistration: 5,
    referralDiscountPercentage: 10,
    enableReferralSystem: true,
    ticketTypes: []
  });
  const [ticketTypes, setTicketTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newTicketType, setNewTicketType] = useState({ name: '', price: '' });

  useEffect(() => {
    const fetchTicketTypes = async () => {
      try {
        const fetchedTicketTypes = await TicketService.getAllTicketTypes();
        setTicketTypes(fetchedTicketTypes);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch ticket types');
        setLoading(false);
      }
    };

    fetchTicketTypes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTicketTypeChange = (index, field, value) => {
    const updatedTicketTypes = [...ticketTypes];
    updatedTicketTypes[index][field] = value;
    setTicketTypes(updatedTicketTypes);
  };

  const handleAddTicketType = async () => {
    if (!newTicketType.name || !newTicketType.price) {
      setError('Please fill in both ticket type and price');
      return;
    }

    try {
      const createdTicketType = await TicketService.createTicketType(newTicketType);
      setTicketTypes([...ticketTypes, createdTicketType]);
      setNewTicketType({ name: '', price: '' });
      setError(null);
    } catch (err) {
      setError('Failed to create ticket type');
    }
  };

  const handleUpdateTicketType = async (ticketType) => {
    try {
      await TicketService.updateTicketType(ticketType._id, ticketType);
      setError(null);
    } catch (err) {
      setError('Failed to update ticket type');
    }
  };

  const handleDeleteTicketType = async (ticketTypeId) => {
    try {
      await TicketService.deleteTicketType(ticketTypeId);
      setTicketTypes(ticketTypes.filter(ticket => ticket.id !== ticketTypeId));
      setError(null);
    } catch (err) {
      setError('Failed to delete ticket type');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Implement save settings logic if needed
      console.log('Settings saved:', settings);
    } catch (err) {
      setError('Failed to save settings');
    }
  };

  if (loading) {
    return (
      <Container fluid className="settings-page text-center">
        <Spinner color="primary" />
      </Container>
    );
  }

  return (
    <Container fluid className="settings-page">
      <Row>
        <Col>
          <PageTitle
            heading="Event Settings"
            subheading="Configure event details and preferences"
            icon={faCog}
            titleClassName="text-primary"
            subtitleClassName="text-muted"
            iconClassName="bg-transparent"
          />
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <Alert color="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={8}>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Event Name</Label>
                  <Input
                    type="text"
                    name="eventName"
                    value={settings.eventName}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Max Participants per Registration</Label>
                      <Input
                        type="number"
                        name="maxParticipantsPerRegistration"
                        value={settings.maxParticipantsPerRegistration}
                        onChange={handleInputChange}
                        min={1}
                        max={10}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Referral Discount Percentage</Label>
                      <Input
                        type="number"
                        name="referralDiscountPercentage"
                        value={settings.referralDiscountPercentage}
                        onChange={handleInputChange}
                        min={0}
                        max={50}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="enableReferralSystem"
                      checked={settings.enableReferralSystem}
                      onChange={(e) => setSettings(prev => ({
                        ...prev,
                        enableReferralSystem: e.target.checked
                      }))}
                    />{' '}
                    Enable Referral System
                  </Label>
                </FormGroup>

                <h4 className="mt-4 mb-3">Ticket Types</h4>
                {ticketTypes.map((ticket, index) => (
                  <Row key={ticket.id} className="mb-2">
                    <Col md={4}>
                      <FormGroup>
                        <Label>Ticket Type</Label>
                        <Input
                          type="text"
                          value={ticket.name}
                          onChange={(e) => handleTicketTypeChange(index, 'name', e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>Price</Label>
                        <Input
                          type="number"
                          value={ticket.price}
                          onChange={(e) => handleTicketTypeChange(index, 'price', parseInt(e.target.value))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} className="d-flex align-items-end">
                      <Button 
                        color="warning" 
                        size="sm" 
                        className="mr-2"
                        onClick={() => handleUpdateTicketType(ticket)}
                      >
                        Update
                      </Button>
                      <Button 
                        color="danger" 
                        size="sm"
                        onClick={() => handleDeleteTicketType(ticket.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                  </Row>
                ))}

                <Row className="mt-3">
                  <Col md={4}>
                    <FormGroup>
                      <Label>New Ticket Type</Label>
                      <Input
                        type="text"
                        placeholder="Type (e.g., Regular)"
                        value={newTicketType.type}
                        onChange={(e) => setNewTicketType(prev => ({
                          ...prev,
                          name: e.target.value
                        }))}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Price</Label>
                      <Input
                        type="number"
                        placeholder="Price"
                        value={newTicketType.price}
                        onChange={(e) => setNewTicketType(prev => ({
                          ...prev,
                          price: parseInt(e.target.value)
                        }))}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="d-flex align-items-end">
                    <Button 
                      color="primary" 
                      size="sm" 
                      onClick={handleAddTicketType}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      Add Ticket Type
                    </Button>
                  </Col>
                </Row>

                <Button color="primary" type="submit" className="mt-3">
                  Save Settings
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
