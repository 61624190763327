import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  ButtonGroup,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Progress,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faFilter,
  faDownload,
  faEllipsisV,
  faSync,
  faTicketAlt,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import './Tickets.scss';

const Tickets = () => {
  const [filters, setFilters] = useState({
    search: '',
    city: '',
    type: '',
    availability: '',
  });

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  // Mock data - replace with API call
  const tickets = [
    {
      id: 1,
      city: 'Mumbai',
      type: 'Regular',
      price: 999,
      totalQuota: 1000,
      sold: 450,
      available: 550,
      status: 'Active',
      lastUpdated: '2024-01-15',
    },
    {
      id: 2,
      city: 'Mumbai',
      type: 'VIP',
      price: 1999,
      totalQuota: 200,
      sold: 150,
      available: 50,
      status: 'Limited',
      lastUpdated: '2024-01-15',
    },
    {
      id: 3,
      city: 'Mumbai',
      type: 'Student',
      price: 499,
      totalQuota: 500,
      sold: 500,
      available: 0,
      status: 'Sold Out',
      lastUpdated: '2024-01-15',
    },
  ];

  const cities = [
    'Mumbai', 'Delhi', 'Bangalore', 'Chennai', 'Kolkata',
    'Hyderabad', 'Pune', 'Ahmedabad', 'Jaipur', 'Surat',
  ];

  const ticketTypes = ['Regular', 'VIP', 'Student'];
  const availabilityOptions = ['All', 'Available', 'Limited', 'Sold Out'];

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }));
  };

  const handleExport = () => {
    // Implement CSV export functionality
  };

  const handleRefresh = () => {
    // Implement data refresh functionality
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active': return 'success';
      case 'limited': return 'warning';
      case 'sold out': return 'danger';
      default: return 'primary';
    }
  };

  const getAvailabilityPercentage = (available, total) => {
    return Math.round((available / total) * 100);
  };

  const getProgressColor = (percentage) => {
    if (percentage > 50) return 'success';
    if (percentage > 20) return 'warning';
    return 'danger';
  };

  const filteredTickets = tickets.filter(ticket => {
    const searchMatch = filters.search
      ? Object.values(ticket).some(value => 
          value.toString().toLowerCase().includes(filters.search.toLowerCase())
        )
      : true;

    const cityMatch = filters.city ? ticket.city === filters.city : true;
    const typeMatch = filters.type ? ticket.type === filters.type : true;
    const availabilityMatch = filters.availability
      ? ticket.status === filters.availability
      : true;

    return searchMatch && cityMatch && typeMatch && availabilityMatch;
  });

  return (
    <Container fluid className="tickets-page">
      <Row className="mb-3">
        <Col>
          <PageTitle
            heading="Ticket Management"
            subheading="Configure and manage event tickets"
            icon={faTicketAlt}
            titleClassName="text-primary"
            subtitleClassName="text-muted"
            iconClassName="bg-transparent"
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={3}>
          <Card className="stats-card">
            <CardBody>
              <div className="stats-icon">
                <FontAwesomeIcon icon={faTicketAlt} />
              </div>
              <div className="stats-info">
                <h3>Total Tickets</h3>
                <p>2,150</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stats-card">
            <CardBody>
              <div className="stats-icon">
                <FontAwesomeIcon icon={faChartBar} />
              </div>
              <div className="stats-info">
                <h3>Tickets Sold</h3>
                <p>1,100</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* Add more stat cards as needed */}
      </Row>

      <Card className="mb-3">
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col md={6}>
              <div className="search-box">
                <Input
                  type="text"
                  placeholder="Search tickets..."
                  value={filters.search}
                  onChange={(e) => handleFilterChange('search', e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div>
            </Col>
            <Col md={6} className="text-right">
              <ButtonGroup>
                <Button
                  color="secondary"
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                  <FontAwesomeIcon icon={faFilter} className="mr-2" />
                  Filters
                </Button>
                <Button color="secondary" onClick={handleExport}>
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  Export
                </Button>
                <Button color="secondary" onClick={handleRefresh}>
                  <FontAwesomeIcon icon={faSync} className="mr-2" />
                  Refresh
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          {showAdvancedFilters && (
            <Row className="mb-3">
              <Col md={4}>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    type="select"
                    value={filters.city}
                    onChange={(e) => handleFilterChange('city', e.target.value)}
                  >
                    <option value="">All Cities</option>
                    {cities.map(city => (
                      <option key={city} value={city}>{city}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Ticket Type</Label>
                  <Input
                    type="select"
                    value={filters.type}
                    onChange={(e) => handleFilterChange('type', e.target.value)}
                  >
                    <option value="">All Types</option>
                    {ticketTypes.map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Availability</Label>
                  <Input
                    type="select"
                    value={filters.availability}
                    onChange={(e) => handleFilterChange('availability', e.target.value)}
                  >
                    <option value="">All Status</option>
                    {availabilityOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Table responsive hover className="tickets-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>City</th>
                <th>Type</th>
                <th>Price</th>
                <th>Total Quota</th>
                <th>Sold</th>
                <th>Available</th>
                <th>Status</th>
                <th>Availability</th>
                <th>Last Updated</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredTickets.map(ticket => (
                <tr key={ticket.id}>
                  <td>{ticket.id}</td>
                  <td>{ticket.city}</td>
                  <td>{ticket.type}</td>
                  <td>₹{ticket.price}</td>
                  <td>{ticket.totalQuota}</td>
                  <td>{ticket.sold}</td>
                  <td>{ticket.available}</td>
                  <td>
                    <Badge color={getStatusBadgeColor(ticket.status)}>
                      {ticket.status}
                    </Badge>
                  </td>
                  <td style={{ width: '150px' }}>
                    <Progress
                      value={getAvailabilityPercentage(ticket.available, ticket.totalQuota)}
                      color={getProgressColor(getAvailabilityPercentage(ticket.available, ticket.totalQuota))}
                      className="mb-0"
                    >
                      {getAvailabilityPercentage(ticket.available, ticket.totalQuota)}%
                    </Progress>
                  </td>
                  <td>{ticket.lastUpdated}</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle color="link" className="p-0">
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>Edit Quota</DropdownItem>
                        <DropdownItem>Update Price</DropdownItem>
                        <DropdownItem>View Sales</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="text-danger">Disable Sales</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Tickets;
