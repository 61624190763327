import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import { MainNav, AdminNav } from './NavItems';

class VerticalNavWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSidebar: false,
      enableMobileMenu: false
    };
  }

  render() {
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Menu</h5>
        <MetisMenu content={[...MainNav, ...AdminNav]} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      </Fragment>
    );
  }
}

export default withRouter(VerticalNavWrapper);
