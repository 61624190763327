import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faTicketAlt, 
  faPrint, 
  faShareAlt,
  faCalendarAlt,
  faMapMarkerAlt,
  faUsers,
  faRupeeSign,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import QRCode from 'react-qr-code';
import { useLocation, useHistory } from 'react-router-dom';
import './RegistrationSuccess.scss';

const TicketCard = ({ participant, city, showDownload = false, onDownload }) => {
  const ticketRef = useRef();

  return (
    <div className="ticket-card-wrapper" ref={ticketRef}>
      <Card className="ticket-card">
        <CardBody>
          <div className="ticket-header">
            <div className="ticket-title">
              <h5>Racefor7 Event Ticket</h5>
              <span className="event-date">February 23, 2025</span>
            </div>
            {showDownload && (
              <Button 
                color="link" 
                className="download-btn"
                onClick={() => onDownload(ticketRef.current)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            )}
          </div>
          
          <div className="ticket-content">
            <div className="ticket-info">
              <div className="participant-details">
                <h6>{participant.name}</h6>
                <p className="ticket-type">{participant.ticketType}</p>
                <p><strong>Email:</strong> {participant.email}</p>
                <p><strong>Phone:</strong> {participant.phone}</p>
                <p><strong>City:</strong> {city}</p>
              </div>
              
              <div className="ticket-qr">
                <QRCode 
                  value={JSON.stringify({
                    ...participant,
                    city,
                    eventDate: '2025-02-23'
                  })}
                  size={100}
                />
              </div>
            </div>
          </div>
          
          <div className="ticket-footer">
            <div className="ticket-number">#{Math.random().toString(36).substr(2, 8).toUpperCase()}</div>
            <img 
              src="https://registration.ordindia.in/images/logo.png" 
              alt="Event Logo" 
              className="event-logo"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const RegistrationSuccess = () => {
  const location = useLocation();
  const history = useHistory();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [fullQrModalOpen, setFullQrModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  
  const registrationDetails = location.state?.registrationDetails || {
    city: 'Mumbai',
    participants: [{
      name: 'John Doe',
      email: 'john@example.com',
      phone: '1234567890',
      ticketType: 'Regular',
      ticketPrice: 999
    }],
    totalAmount: 999,
    referralCode: '',
    donationAmount: 0
  };

  const handlePrintTicket = () => {
    window.print();
  };

  const handleBackToHome = () => {
    history.push('/');
  };

  const toggleShareModal = () => {
    setShareModalOpen(!shareModalOpen);
  };

  const toggleFullQrModal = (ticket = null) => {
    setSelectedTicket(ticket);
    setFullQrModalOpen(!fullQrModalOpen);
  };

  const handleDownloadTicket = async (element) => {
    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        backgroundColor: null,
        logging: false
      });
      
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'racefor7-ticket.png';
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error('Error downloading ticket:', error);
    }
  };

  const shareEvent = (platform) => {
    const eventDetails = `I'm participating in Racefor7 event in ${registrationDetails.city} on 23-02-2025!`;
    const shareUrl = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(eventDetails)}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(eventDetails)}&url=${encodeURIComponent(window.location.href)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(eventDetails + ' ' + window.location.href)}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(eventDetails)}`
    }[platform];
    
    window.open(shareUrl, '_blank');
  };

  return (
    <div className="registration-success-page">
      <Container>
        <Row>
          <Col lg={8} className="mx-auto">
            <Card className="main-card mb-4">
              <CardBody>
              <Alert color="success" className="text-center mb-4 success-banner">
          <FontAwesomeIcon icon={faCheckCircle} className="d-block mx-auto mb-2" size="2x" />
          <h3 className="mb-2">Registration Successful!</h3>
          <p className="mb-0">Thank you for registering for Racefor7. Your registration has been confirmed.</p>
        </Alert>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  
                  <h4 className="mb-0">
                    <FontAwesomeIcon icon={faTicketAlt} className="me-2" />
                    Event Details
                  </h4>
                  <div className="no-print">
                    <Button color="primary" className="me-2" onClick={handlePrintTicket}>
                      <FontAwesomeIcon icon={faPrint} className="me-2" />
                      Print All
                    </Button>
                    <Button color="success" onClick={toggleShareModal}>
                      <FontAwesomeIcon icon={faShareAlt} className="me-2" />
                      Share
                    </Button>
                  </div>
                </div>

                <div className="event-info">
                  <div className="info-item">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <strong>City:</strong> {registrationDetails.city}
                  </div>
                  <div className="info-item">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <strong>Event Date:</strong> February 23, 2025
                  </div>
                  <div className="info-item">
                    <FontAwesomeIcon icon={faUsers} />
                    <strong>Total Participants:</strong> {registrationDetails.participants.length}
                  </div>
                  <div className="info-item">
                    <FontAwesomeIcon icon={faRupeeSign} />
                    <strong>Total Amount:</strong> ₹{registrationDetails.totalAmount}
                  </div>
                </div>

                <h5 className="mb-3">Your Tickets</h5>
                
                {/* Desktop and Mobile View */}
                <div className="tickets-container">
                  {registrationDetails.participants.map((participant, index) => (
                    <TicketCard
                      key={index}
                      participant={participant}
                      city={registrationDetails.city}
                      showDownload={true}
                      onDownload={handleDownloadTicket}
                    />
                  ))}
                </div>

                <div className="text-center mt-4">
                  <Button color="secondary" onClick={handleBackToHome}>
                    Back to Home
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Share Modal */}
        <Modal isOpen={shareModalOpen} toggle={toggleShareModal} className="share-modal">
          <ModalHeader toggle={toggleShareModal}>
            Share Your Registration
          </ModalHeader>
          <ModalBody>
            <div className="social-share-buttons">
              <Button className="facebook" onClick={() => shareEvent('facebook')}>
                <FontAwesomeIcon icon={faFacebook} />
              </Button>
              <Button className="twitter" onClick={() => shareEvent('twitter')}>
                <FontAwesomeIcon icon={faTwitter} />
              </Button>
              <Button className="whatsapp" onClick={() => shareEvent('whatsapp')}>
                <FontAwesomeIcon icon={faWhatsapp} />
              </Button>
              <Button className="linkedin" onClick={() => shareEvent('linkedin')}>
                <FontAwesomeIcon icon={faLinkedin} />
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* QR Code Modal */}
        <Modal isOpen={fullQrModalOpen} toggle={() => toggleFullQrModal()} className="qr-modal">
          <ModalHeader toggle={() => toggleFullQrModal()}>
            Ticket QR Code
          </ModalHeader>
          <ModalBody>
            {selectedTicket && (
              <>
                <QRCode 
                  value={JSON.stringify(selectedTicket)}
                  size={250}
                />
                <div className="qr-details">
                  <h5>{selectedTicket.name}</h5>
                  <p><strong>Email:</strong> {selectedTicket.email}</p>
                  <p><strong>Phone:</strong> {selectedTicket.phone}</p>
                  <p><strong>Ticket Type:</strong> {selectedTicket.ticketType}</p>
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default RegistrationSuccess;
