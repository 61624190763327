import React, { useState } from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Badge
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faUser,
  faEnvelope,
  faUserTag,
  faSave,
  faEdit,
  faTrash,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import './Users.scss';

const Users = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: 'Admin User',
      email: 'admin@example.com',
      role: 'Administrator',
      lastLogin: '2024-01-15 10:30:00',
      permissions: {
        viewTickets: true,
        markPresence: true
      }
    }
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: null,
    name: '',
    email: '',
    role: 'User',
    permissions: {
      viewTickets: false,
      markPresence: false
    }
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      // Reset form when opening
      setCurrentUser({
        id: null,
        name: '',
        email: '',
        role: 'User',
        permissions: {
          viewTickets: false,
          markPresence: false
        }
      });
      setIsEditMode(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePermissionChange = (permission) => {
    setCurrentUser(prev => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [permission]: !prev.permissions[permission]
      }
    }));
  };

  const saveUser = () => {
    // Basic validation
    if (!currentUser.name || !currentUser.email) {
      alert('Please fill in all required fields');
      return;
    }

    if (isEditMode) {
      // Update existing user
      setUsers(prev => 
        prev.map(user => 
          user.id === currentUser.id ? currentUser : user
        )
      );
    } else {
      // Create new user
      const userToAdd = {
        ...currentUser,
        id: users.length + 1,
        lastLogin: 'Not logged in'
      };
      setUsers(prev => [...prev, userToAdd]);
    }

    // Close modal and reset form
    toggleModal();
  };

  const editUser = (user) => {
    setCurrentUser(user);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const deleteUser = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      setUsers(prev => prev.filter(user => user.id !== userId));
    }
  };

  return (
    <Container fluid className="users-page">
      <PageTitle
        heading="User Management"
        subheading="Create, edit, and manage user accounts with specific permissions"
        icon={faUser}
        titleClassName="text-primary"
        subtitleClassName="text-muted"
        iconClassName="bg-transparent"
      />
      
      <Row className="mb-3">
        <Col>
          <Button 
            color="primary" 
            onClick={toggleModal}
            className="float-end"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add User
          </Button>
        </Col>
      </Row>
      
      <Card>
        <CardBody>
          <Table responsive hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Permissions</th>
                <th>Last Login</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <Badge 
                      color={user.permissions.viewTickets ? 'success' : 'secondary'} 
                      className="me-2"
                    >
                      {user.permissions.viewTickets ? 'View Tickets' : 'No Ticket View'}
                    </Badge>
                    <Badge 
                      color={user.permissions.markPresence ? 'success' : 'secondary'}
                    >
                      {user.permissions.markPresence ? 'Mark Presence' : 'No Presence'}
                    </Badge>
                  </td>
                  <td>{user.lastLogin}</td>
                  <td>
                    <Button 
                      color="warning" 
                      size="sm" 
                      className="me-2"
                      onClick={() => editUser(user)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="me-1" />
                      Edit
                    </Button>
                    <Button 
                      color="danger" 
                      size="sm"
                      onClick={() => deleteUser(user.id)}
                      disabled={user.role === 'Administrator'}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-1" />
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          <FontAwesomeIcon icon={isEditMode ? faEdit : faPlus} className="me-2" />
          {isEditMode ? 'Edit User' : 'Add New User'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    Name
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={currentUser.name}
                    onChange={handleInputChange}
                    placeholder="Enter user's full name"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    Email
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    value={currentUser.email}
                    onChange={handleInputChange}
                    placeholder="Enter user's email address"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    <FontAwesomeIcon icon={faUserTag} className="me-2" />
                    Role
                  </Label>
                  <Input
                    type="select"
                    name="role"
                    value={currentUser.role}
                    onChange={handleInputChange}
                  >
                    <option value="User">User</option>
                    <option value="Administrator">Administrator</option>
                    <option value="Editor">Editor</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Permissions</Label>
                  <div>
                    <Button
                      color={currentUser.permissions.viewTickets ? 'success' : 'secondary'}
                      onClick={() => handlePermissionChange('viewTickets')}
                      className="me-2"
                    >
                      <FontAwesomeIcon 
                        icon={currentUser.permissions.viewTickets ? faCheckCircle : faTimesCircle} 
                        className="me-2" 
                      />
                      View Tickets
                    </Button>
                    <Button
                      color={currentUser.permissions.markPresence ? 'success' : 'secondary'}
                      onClick={() => handlePermissionChange('markPresence')}
                    >
                      <FontAwesomeIcon 
                        icon={currentUser.permissions.markPresence ? faCheckCircle : faTimesCircle} 
                        className="me-2" 
                      />
                      Mark Presence
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveUser}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Save User
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Users;
