import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  ButtonGroup,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faFilter,
  faDownload,
  faEllipsisV,
  faSync,
  faTicketAlt,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Registrations.scss';

const Registrations = () => {
  const [filters, setFilters] = useState({
    search: '',
    city: '',
    ticketType: '',
    status: '',
    startDate: null,
    endDate: null,
  });

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

  // Mock data - replace with API call
  const registrations = [
    {
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      phone: '+91 9876543210',
      city: 'Mumbai',
      ticketType: 'VIP',
      status: 'Confirmed',
      amount: 1999,
      registrationDate: '2024-01-15',
      organization: 'Tech Corp',
      tshirtSize: 'L',
      referralCode: 'TECH10',
      participants: [
        {
          name: 'John Doe',
          email: 'john@example.com',
          ticketType: 'VIP',
          tshirtSize: 'L',
          paymentReference: 'RAZPAY_12345'
        },
        {
          name: 'Jane Doe',
          email: 'jane@example.com',
          ticketType: 'VIP',
          tshirtSize: 'M',
          paymentReference: 'RAZPAY_67890'
        }
      ]
    },
    // Add more mock data as needed
  ];

  const cities = [
    'Mumbai', 'Delhi', 'Bangalore', 'Chennai', 'Kolkata',
    'Hyderabad', 'Pune', 'Ahmedabad', 'Jaipur', 'Surat',
  ];

  const ticketTypes = ['Regular', 'VIP', 'Student'];
  const statuses = ['Confirmed', 'Pending', 'Cancelled'];

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }));
  };

  const handleExport = () => {
    // Implement CSV export functionality
  };

  const handleRefresh = () => {
    // Implement data refresh functionality
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'confirmed': return 'success';
      case 'pending': return 'warning';
      case 'cancelled': return 'danger';
      default: return 'primary';
    }
  };

  const toggleTicketModal = (registration) => {
    setSelectedRegistration(registration);
    setIsTicketModalOpen(!isTicketModalOpen);
  };

  const filteredRegistrations = registrations.filter(reg => {
    const searchMatch = filters.search
      ? Object.values(reg).some(value => 
          value.toString().toLowerCase().includes(filters.search.toLowerCase())
        )
      : true;

    const cityMatch = filters.city ? reg.city === filters.city : true;
    const ticketMatch = filters.ticketType ? reg.ticketType === filters.ticketType : true;
    const statusMatch = filters.status ? reg.status === filters.status : true;
    
    const dateMatch = filters.startDate && filters.endDate
      ? new Date(reg.registrationDate) >= filters.startDate &&
        new Date(reg.registrationDate) <= filters.endDate
      : true;

    return searchMatch && cityMatch && ticketMatch && statusMatch && dateMatch;
  });

  return (
    <Container fluid className="registrations-page">
      <Row className="mb-3">
        <Col>
          <PageTitle
            heading="Registrations"
            subheading="Manage event registrations"
            icon={faClipboardList}
          />
        </Col>
      </Row>

      <Card className="mb-3">
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col md={6}>
              <div className="search-box">
                <Input
                  type="text"
                  placeholder="Search registrations..."
                  value={filters.search}
                  onChange={(e) => handleFilterChange('search', e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div>
            </Col>
            <Col md={6} className="text-right">
              <ButtonGroup>
                <Button
                  color="secondary"
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                  <FontAwesomeIcon icon={faFilter} className="mr-2" />
                  Filters
                </Button>
                <Button color="secondary" onClick={handleExport}>
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  Export
                </Button>
                <Button color="secondary" onClick={handleRefresh}>
                  <FontAwesomeIcon icon={faSync} className="mr-2" />
                  Refresh
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          {showAdvancedFilters && (
            <Row className="mb-3">
              <Col md={3}>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    type="select"
                    value={filters.city}
                    onChange={(e) => handleFilterChange('city', e.target.value)}
                  >
                    <option value="">All Cities</option>
                    {cities.map(city => (
                      <option key={city} value={city}>{city}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Ticket Type</Label>
                  <Input
                    type="select"
                    value={filters.ticketType}
                    onChange={(e) => handleFilterChange('ticketType', e.target.value)}
                  >
                    <option value="">All Types</option>
                    {ticketTypes.map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                  >
                    <option value="">All Statuses</option>
                    {statuses.map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Registration Date</Label>
                  <div className="d-flex">
                    <DatePicker
                      selected={filters.startDate}
                      onChange={date => handleFilterChange('startDate', date)}
                      selectsStart
                      startDate={filters.startDate}
                      endDate={filters.endDate}
                      className="form-control"
                      placeholderText="Start Date"
                    />
                    <DatePicker
                      selected={filters.endDate}
                      onChange={date => handleFilterChange('endDate', date)}
                      selectsEnd
                      startDate={filters.startDate}
                      endDate={filters.endDate}
                      minDate={filters.startDate}
                      className="form-control ml-2"
                      placeholderText="End Date"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Table responsive hover>
            <thead>
              <tr>
                <th>Registration ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>City</th>
                <th>Ticket Type</th>
                <th>Tickets</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRegistrations.map(reg => (
                <tr key={reg.id}>
                  <td>{reg.id}</td>
                  <td>{reg.name}</td>
                  <td>{reg.email}</td>
                  <td>{reg.city}</td>
                  <td>{reg.ticketType}</td>
                  <td>
                    <Button 
                      color="link" 
                      onClick={() => toggleTicketModal(reg)}
                      className="p-0"
                    >
                      <Badge color="primary">
                        {reg.participants.length} <FontAwesomeIcon icon={faTicketAlt} />
                      </Badge>
                    </Button>
                  </td>
                  <td>
                    <Badge color={getStatusBadgeColor(reg.status)}>
                      {reg.status}
                    </Badge>
                  </td>
                  <td>₹{reg.amount}</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="span" className="text-muted cursor-pointer">
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>View Details</DropdownItem>
                        <DropdownItem>Edit</DropdownItem>
                        <DropdownItem>Cancel Registration</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      {/* Ticket Details Modal */}
      <Modal 
        isOpen={isTicketModalOpen} 
        toggle={() => setIsTicketModalOpen(!isTicketModalOpen)}
        size="lg"
      >
        <ModalHeader toggle={() => setIsTicketModalOpen(!isTicketModalOpen)}>
          Ticket Details for Registration #{selectedRegistration?.id}
        </ModalHeader>
        <ModalBody>
          {selectedRegistration && (
            <>
              <Row className="mb-3">
                <Col>
                  <h5 className="mb-0">Payment Reference</h5>
                  <p className="text-muted">{selectedRegistration.participants[0]?.paymentReference}</p>
                </Col>
              </Row>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Ticket Type</th>
                    <th>T-Shirt Size</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRegistration.participants.map((participant, index) => (
                    <tr key={index}>
                      <td>{participant.name}</td>
                      <td>{participant.email}</td>
                      <td>{participant.ticketType}</td>
                      <td>{participant.tshirtSize}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsTicketModalOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Registrations;
