export const MainNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Dashboard',
        to: "/admin/dashboard",
    },
    {
        icon: "pe-7s-ticket",
        label: "Registrations",
        to: "/admin/registrations",
    },
    {
        icon: "pe-7s-users",
        label: "Users",
        to: "/admin/users",
    },
    {
        icon: "pe-7s-ticket",
        label: "Tickets",
        to: "/admin/tickets",
    },
    {
        icon: "pe-7s-map-marker",
        label: "Cities",
        to: "/admin/cities",
    },
    {
        icon: "pe-7s-config",
        label: "Settings",
        to: "/admin/settings",
    },
];

export const AdminNav = [
    {
        icon: 'pe-7s-ticket',
        label: 'Coupon Management',
        to: '/admin/coupons',
    },
];
