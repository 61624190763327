import React, { Component } from 'react';
import { Button } from 'reactstrap';
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TitleComponent1 from "./PageTitleExamples/Variation1";
import TitleComponent2 from "./PageTitleExamples/Variation2";
import TitleComponent3 from "./PageTitleExamples/Variation3";

class PageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: props.heading || 'Event Registration',
      subheading: props.subheading || 'Welcome to the Event Registration System',
      icon: props.icon || null
    };
  }

  randomize(myArray) {
    return myArray[Math.floor(Math.random() * myArray.length)];
  }

  render() {
    let {
      enablePageTitleIcon = true,
      enablePageTitleSubheading = true,
      className = '',
      iconClassName = '',
      titleClassName = '',
      subtitleClassName = ''
    } = this.props;

    var arr = [<TitleComponent1 />, <TitleComponent2 />, <TitleComponent3 />];

    return (
      <div className={`app-page-title ${className}`} style={{
        backgroundColor: '#f4f5f7', 
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
      }}>
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={cx("page-title-icon", iconClassName, {
                "d-none": !enablePageTitleIcon || !this.state.icon,
              })} style={{
                backgroundColor: 'rgba(0, 123, 255, 0.1)', 
                width: '70px',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '20px'
              }}>
              {enablePageTitleIcon && this.state.icon && (
                typeof this.state.icon === 'string' ? 
                  <i className={this.state.icon} style={{fontSize: '2.5rem'}}/> : 
                  <FontAwesomeIcon 
                    icon={this.state.icon} 
                    style={{
                      fontSize: '2.5rem', 
                      color: '#007bff', 
                      opacity: 0.9
                    }}
                  />
              )}
            </div>
            <div>
              <div 
                className={`page-title-main ${titleClassName}`} 
                style={{
                  fontSize: '1.8rem', 
                  fontWeight: '600', 
                  color: '#2f3542'
                }}
              >
                {this.state.heading}
              </div>
              {enablePageTitleSubheading && this.state.subheading && (
                <div 
                  className={`page-title-subheading ${subtitleClassName}`}
                  style={{
                    fontSize: '1rem', 
                    color: '#747d8c', 
                    marginTop: '5px'
                  }}
                >
                  {this.state.subheading}
                </div>
              )}
            </div>
          </div>
          <div className="page-title-actions">
            {this.randomize(arr)}
            <Button
              className="btn-shadow mr-3"
              color="success"
              onClick={() => {}}
            >
              Create Event
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PageTitle;
