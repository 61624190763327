import React, { useState, useEffect } from 'react';
import { Container, Form, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../../services/api';
import PageTitle from '../../Layout/AppMain/PageTitle';

const CouponManagement = () => {
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [referral, setReferral] = useState('');
  const [discount, setDiscount] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await api.get('/coupons');
      setCoupons(response.data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  const handleAddCoupon = async (e) => {
    e.preventDefault();
    try {
      await api.post('/coupons', { code: couponCode, referral, discount });
      fetchCoupons();
      toggleModal();
      setCouponCode('');
      setReferral('');
      setDiscount('');
    } catch (error) {
      console.error('Error adding coupon:', error);
    }
  };

  const handleDeleteCoupon = async (id) => {
    try {
      await api.delete(`/coupons/${id}`);
      fetchCoupons();
    } catch (error) {
      console.error('Error deleting coupon:', error);
    }
  };

  const toggleModal = () => setModal(!modal);

  return (
    <Container fluid>
      <PageTitle heading="Coupon Management" />
      <Button color="primary" onClick={toggleModal}>Add Coupon</Button>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Coupon</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleAddCoupon}>
            <FormGroup>
              <Label for="couponCode">Coupon Code</Label>
              <Input type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="referral">Referral</Label>
              <Input type="text" value={referral} onChange={(e) => setReferral(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="discount">Discount (%)</Label>
              <Input type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} required />
            </FormGroup>
            <ModalFooter>
              <Button type="submit" color="primary">Add Coupon</Button>
              <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
      <Table striped>
        <thead>
          <tr>
            <th>Coupon Code</th>
            <th>Referral</th>
            <th>Discount (%)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => (
            <tr key={coupon.id}>
              <td>{coupon.code}</td>
              <td>{coupon.referral}</td>
              <td>{coupon.discount}</td>
              <td>
                <Button color="danger" onClick={() => handleDeleteCoupon(coupon.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default CouponManagement;
