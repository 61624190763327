import axios from 'axios';

// Base configuration for axios
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Interceptor to add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Authentication Services
export const AuthService = {
  // User Registration
  register: async (userData) => {
    try {
      const response = await api.post('/auth/register', userData);
      // Store token and user info
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data;
    } catch (error) {
      console.error('Registration Error:', error.response?.data);
      throw error.response?.data || new Error('Registration failed');
    }
  },

  // User Login
  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', credentials);
      // Store token and user info
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data;
    } catch (error) {
      console.error('Login Error:', error.response?.data);
      throw error.response?.data || new Error('Login failed');
    }
  },

  // Logout
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },

  // Check Referral Validity
  checkReferral: async (referralCode) => {
    try {
      const response = await api.post('/auth/validate-referral', { referralCode });
      return response.data;
    } catch (error) {
      console.error('Referral Validation Error:', error.response?.data);
      throw error.response?.data || new Error('Referral validation failed');
    }
  }
};

// User Management Services
export const UserService = {
  // Get all users (admin only)
  getAllUsers: async () => {
    try {
      const response = await api.get('/users');
      return response.data;
    } catch (error) {
      console.error('Fetch Users Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to fetch users');
    }
  },

  // Create a new user (admin only)
  createUser: async (userData) => {
    try {
      const response = await api.post('/users', userData);
      return response.data;
    } catch (error) {
      console.error('Create User Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to create user');
    }
  },

  // Update user details
  updateUser: async (userId, userData) => {
    try {
      const response = await api.put(`/users/${userId}`, userData);
      return response.data;
    } catch (error) {
      console.error('Update User Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to update user');
    }
  },

  // Delete a user (admin only)
  deleteUser: async (userId) => {
    try {
      const response = await api.delete(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Delete User Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to delete user');
    }
  }
};

// Cities Management Services
export const CityService = {
  // Get all cities
  getAllCities: async () => {
    try {
      const response = await api.get('/cities');
      return response.data;
    } catch (error) {
      console.error('Fetch Cities Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to fetch cities');
    }
  },

  // Create a new city (admin only)
  createCity: async (cityData) => {
    try {
      const response = await api.post('/cities', cityData);
      return response.data;
    } catch (error) {
      console.error('Create City Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to create city');
    }
  },

  // Update city details
  updateCity: async (cityId, cityData) => {
    try {
      const response = await api.put(`/cities/${cityId}`, cityData);
      return response.data;
    } catch (error) {
      console.error('Update City Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to update city');
    }
  },

  // Delete a city (admin only)
  deleteCity: async (cityId) => {
    try {
      const response = await api.delete(`/cities/${cityId}`);
      return response.data;
    } catch (error) {
      console.error('Delete City Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to delete city');
    }
  },

  // Get a specific city by ID
  getCityById: async (cityId) => {
    try {
      const response = await api.get(`/cities/${cityId}`);
      return response.data;
    } catch (error) {
      console.error('Fetch City Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to fetch city');
    }
  }
};

// Ticket Management Services
export const TicketService = {
  // Get all ticket types
  getAllTicketTypes: async () => {
    try {
      const response = await api.get('/ticket-types');
      return response.data;
    } catch (error) {
      console.error('Fetch Ticket Types Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to fetch ticket types');
    }
  },

  // Create a new ticket type (admin only)
  createTicketType: async (ticketTypeData) => {
    try {
      const response = await api.post('/ticket-types', ticketTypeData);
      return response.data;
    } catch (error) {
      console.error('Create Ticket Type Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to create ticket type');
    }
  },

  // Update ticket type details
  updateTicketType: async (ticketTypeId, ticketTypeData) => {
    try {
      const response = await api.put(`/ticket-types/${ticketTypeId}`, ticketTypeData);
      return response.data;
    } catch (error) {
      console.error('Update Ticket Type Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to update ticket type');
    }
  }
};

// Event Settings Service
export const SettingsService = {
  // Get current event settings
  getEventSettings: async () => {
    try {
      const response = await api.get('/settings');
      return response.data;
    } catch (error) {
      console.error('Fetch Settings Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to fetch event settings');
    }
  },

  // Update event settings (admin only)
  updateEventSettings: async (settingsData) => {
    try {
      const response = await api.put('/settings', settingsData);
      return response.data;
    } catch (error) {
      console.error('Update Settings Error:', error.response?.data);
      throw error.response?.data || new Error('Failed to update event settings');
    }
  }
};

// Registration Service
export const RegistrationService = {
  async register(registrationDetails) {
    try {
      const response = await api.post('/registrations', registrationDetails);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : 'Registration failed';
    }
  }
};

export default api;
