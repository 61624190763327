import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Alert
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCity,
  faPlus,
  faEdit,
  faTrash,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import { CityService, TicketService } from '../../../services/api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import './Cities.scss';

const initialCityState = {
  name: '',
  slug: '',
  venueAddress: '',
  cityPhoto: null,
  ticketTypes: []
};

const Cities = () => {
  const [cities, setCities] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [currentCity, setCurrentCity] = useState(initialCityState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  // Fetch cities and ticket types on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch cities
        const fetchedCities = await CityService.getAllCities();
        setCities(fetchedCities);

        // Fetch ticket types
        const fetchedTicketTypes = await TicketService.getAllTicketTypes();
        setTicketTypes(fetchedTicketTypes);

        setLoading(false);
      } catch (err) {
        setError(err.message || 'Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCity(prev => ({
      ...prev,
      [name]: value,
      slug: name === 'name' ? value.toLowerCase().replace(/\s+/g, '-') : prev.slug
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentCity(prev => ({
          ...prev,
          cityPhoto: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTicketTypeToggle = (ticketTypeId) => {

    const ticketType = ticketTypes.find(tt => tt._id === ticketTypeId);
    console.log(ticketType);
    setCurrentCity(prev => {
      const existingTicketTypeIndex = prev.ticketTypes.findIndex(
        t => t._id === ticketTypeId
      );

      if (existingTicketTypeIndex > -1) {
        // Remove ticket type if already exists
        return {
          ...prev,
          ticketTypes: prev.ticketTypes.filter(t => t._id !== ticketTypeId)
        };
      } else {
        // Add ticket type with all necessary data
        return {
          ...prev,
          ticketTypes: [
            ...prev.ticketTypes,
            {
              _id: ticketType._id,
              price: ticketType.price,
              available: true
            }
          ]
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const cityData = {
        name: currentCity.name,
        venueAddress: currentCity.venueAddress,
        cityPhoto: currentCity.cityPhoto,
        ticketTypes: currentCity.ticketTypes.map(t => t._id)
      };

      if (isEditing) {
        // Update existing city
        const updatedCity = await CityService.updateCity(currentCity._id, cityData);

        // Update local state
        setCities(prev => prev.map(city => 
          city._id === updatedCity._id ? updatedCity : city
        ));

        setMessage('City updated successfully!');
      } else {
        // Create new city
        const newCity = await CityService.createCity(cityData);

        // Update local state
        setCities(prev => [...prev, newCity]);
        setMessage('City created successfully!');
      }

      // Reset form and close modal
      setCurrentCity(initialCityState);
      setIsModalOpen(false);
    } catch (err) {
      setError(err.message || 'Failed to save city');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const editCity = (city) => {
    setCurrentCity(city);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const deleteCity = async (cityId) => {
    if (window.confirm('Are you sure you want to delete this city?')) {
      try {
        setLoading(true);
        await CityService.deleteCity(cityId);
        
        // Update local state
        setCities(prev => prev.filter(city => city.id !== cityId));
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Failed to delete city');
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <Container fluid className="cities-management text-center">
        <Spinner color="primary" />
        <p>Loading cities...</p>
      </Container>
    );
  }

  return (
    <Container fluid className="cities-management">
      {error && (
        <Alert color="danger">{error}</Alert>
      )}
      {message && (
        <Alert color="success">{message}</Alert>
      )}
      <Row>
        <Col>
          <PageTitle
            heading="Cities Management"
            subheading="Manage cities and their details"
            icon={faCity}
            titleClassName="text-primary"
            subtitleClassName="text-muted"
            iconClassName="bg-transparent"
          />
          
          <Button 
            color="primary" 
            onClick={toggleModal}
            className="mb-3"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" /> 
            Add New City
          </Button>

          <Table responsive striped>
            <thead>
              <tr>
                <th>City Name</th>
                <th>Venue Address</th>
                <th>Ticket Types</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cities.map((city) => (
                <tr key={city.id}>
                  <td>{city.name}</td>
                  <td>{city.venueAddress}</td>
                  <td>
                    {city.ticketTypes.map(ticket => {
                      const ticketType = ticketTypes.find(t => t.id === ticket.id);
                      return ticketType 
                        ? `${ticketType.name} (₹${ticket.price}) ${ticket.available ? '✓' : '✗'}` 
                        : 'N/A'
                    }).join(', ')}
                  </td>
                  <td>
                    <Button 
                      color="warning" 
                      size="sm" 
                      className="me-2"
                      onClick={() => editCity(city)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="me-1" /> 
                      Edit
                    </Button>
                    <Button 
                      color="danger" 
                      size="sm"
                      onClick={() => deleteCity(city.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-1" /> 
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* City Modal */}
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader>
              {isEditing ? 'Edit City' : 'Add New City'}
            </ModalHeader>
            <ModalBody>
              <Form id="cityForm" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>City Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={currentCity.name}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Venue Address</Label>
                  <Input
                    type="text"
                    name="venueAddress"
                    value={currentCity.venueAddress}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>City Photo</Label>
                  <Input
                    type="file"
                    name="cityPhoto"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                  {currentCity.cityPhoto && (
                    <img 
                      src={currentCity.cityPhoto} 
                      alt="City preview" 
                      className="mt-2"
                      style={{ maxWidth: '200px' }}
                    />
                  )}
                </FormGroup>

                <FormGroup>
                  <Label>Ticket Types</Label>
                  <div>
                    {ticketTypes.map(ticketType => (
                      <Button
                        key={ticketType._id}
                        color={
                          currentCity.ticketTypes.some(t => t._id === ticketType._id) 
                            ? 'primary' 
                            : 'outline-primary'
                        }
                        onClick={() => handleTicketTypeToggle(ticketType._id)}
                        type="button"
                        className="me-2 mb-2"
                      >
                        {ticketType.name} (₹{ticketType.price})
                      </Button>
                    ))}
                  </div>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal} type="button">
                Cancel
              </Button>
              <Button color="primary" type="submit" form="cityForm">
                <FontAwesomeIcon icon={faSave} className="me-2" />
                Save City
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default Cities;
