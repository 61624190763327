import React, { Fragment } from "react";
import { Slider } from "react-burgers";

import AppMobileMenu from "../AppMobileMenu";

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    };
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    return (
      <Fragment>
        <div className="app-header__logo">
          <div className="logo logo-dark" style={{ fontSize: '24px', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', letterSpacing: '1px' }}>Racefor7</div>
          <div className="header__pane ms-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d"
                active={this.state.active} onClick={() => this.setState({ active: !this.state.active })}/>
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}

export default HeaderLogo;
